import React from 'react';
import { brand } from '../data'
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper khhgce8abct-editor_css',
        },
        bg: { className: 'bg khhgcvu3wip-editor_css' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper khhgd4bgq3-editor_css',
        },
        bg: { className: 'bg khhgdavq2qq-editor_css' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'content~kh74pin7vmr',
        className: '',
        children: (
          <span>
            <p>BRAND INTRODUCTION</p>
          </span>
        ),
      },
      {
        name: 'title',
        children: (
          <span>
            <p>品牌介绍</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: brand.info,
        className: 'title-content',
      }
    ],
  },
};
export const Feature30DataSource = {
  wrapper: {
    className: 'home-page-wrapper content3-wrapper kh74zcr3gf-editor_css',
  },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>BRAND TONALITY</p>
          </span>
        ),
      },
      {
        name: 'title',
        children: brand.character.title,
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: brand.character.list.map((value) => {
      return {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: value.icon,
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: value.titile,
          },
          content: {
            className: 'content3-content',
            children: value.detail,
          },
        },
      };
    }),
  },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 khhgbdw5qo-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper khhgaw8ispp-editor_css' },
  title: {
    className: 'banner0-title',
    children: (
      <span>
        门店形象
      </span>
    ),
  },
  content: {
    className: 'banner0-content',
    children: (
      <span>
        查看更多
      </span>
    ),
  },
  button: {
    className: 'banner0-button khhgb0yki96-editor_css',
    children: (
      <span>
        <p>了解</p>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/tvQTfCupGUFKSfQ.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>热卤小食</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          贯彻“老味道，新创造“的主张，通过新食材、新工艺、新吃法，呈现出碗碗盛香的创新可口热卤产品。
        </p>
      </span>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>饱腹主食</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <p>
            全球搜寻优质食材，打破传统卤味食材低廉的印象边界，让创意食材与传统热卤碰撞出无限可能；
          </p>
          <p>颠覆传统老卤工艺，拒绝香精等人工添加剂，坚持每日鲜卤；</p>
        </span>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/tvQTfCupGUFKSfQ.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>清甜冰粉</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          <span>
            当日鲜卤
            现拌鲜吃，原创性地提出“热卤口味定制”概念，针对性满足消费者的多重需求。
          </span>
          <br />
        </p>
      </span>
    ),
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'content',
        className: 'feature7-title-content',
        children: (
          <span>
            <p>BRAND ADVANTAGE</p>
          </span>
        ),
      },
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <p>品牌优势</p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>新式热卤开启者</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    颠覆传统卤味店以往作为街边小店的传统形象，定义全新“新式热卤”品牌概念，门店遍布全国各大购物中心。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>强大创新研发团队</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    全球搜罗优选食材，坚持原创且创新的热卤产品线。摒弃老卤，拒绝香精等人工添加剂，坚持健康美味出品，一日一卤。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>快速开拓占领市场</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    卤一嘴成立至今，全国已拥有数十家门店，遍布湖南、湖北、广东、江苏、江西、陕西、河南、贵州、山东…等市场。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>步履不停不断优化</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>
                      不断优化细节，创新性打造“城市热卤厨房”门店概念，对于城市人群内心洞察，创造治愈性的IP形象。<br />
                      <br />
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content90DataSource = {
  wrapper: { className: 'home-page-wrapper content9-wrapper' },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'content~kh75240tdjl',
        className: '',
        children: (
          <span>
            <p>DEVELOPMENT HISTORY</p>
          </span>
        ),
      },
      {
        name: 'title',
        children: (
          <span>
            <p>发展历程</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3139776052,3873472503&fm=11&gp=0.jpg',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          name: {
            className: 'block-name',
            children: (
              <span>
                <span>
                  <p>卤一嘴深圳店</p>
                </span>
              </span>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <span>
                <span>
                  <p>2020年9月15日</p>
                </span>
              </span>
            ),
          },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>2020年9月15日</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>卤一嘴深圳店开业</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>卤一嘴深圳店开业，卤一嘴进驻深圳万象城。</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};

import React from 'react';
import gzh_code from '../images/gzh_code.jpg'
import mini_code from '../images/mini_code.jpg'
import contact_code from '../images/contact_code.jpg'
import logo from '../images/logo2.png'
import { footer } from '../data';
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper kh5tk5evuhm-editor_css' },
  page: { className: 'home-page' },
  logo: { className: 'header2-logo', children: '/logo.png' },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      { name: 'linkNav', to: '/', children: '首页', className: 'menu-item' },
      {
        name: 'linkNav~kh5tkth4c7',
        to: '/brand',
        children: '品牌介绍',
        className: 'menu-item',
      },
      {
        name: 'linkNav~kh5tkur7nlk',
        to: '/product',
        children: '产品中心',
        className: 'menu-item',
      },
      {
        name: 'linkNav~kh5tkvmeog',
        to: '/merchant',
        children: '特许经营',
        className: 'menu-item',
      },
      // {
      //   name: 'linkNav~kh5tkwnkvk',
      //   to: '/contact',
      //   children: '在线招聘',
      //   className: 'menu-item',
      // },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page khhdfkzko4j-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: { className: 'logo', children: logo },
        childWrapper: {
          className: 'slogan',
          children: footer.contact.map((value) => {
            return {
              name: 'content0',
              children: value,
            };
          }),
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>公众号</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'image~khhdjaooxvf',
              className: 'khhdjmeoz6l-editor_css',
              children: (<img src={gzh_code} width="100%" alt="img" />),
            },
          ],
          className: 'khhdjwv4lye-editor_css',
        },
      },
      {
        name: 'block~khhdr1xqlgd',
        xs: 24,
        md: 6,
        className: 'block khhdri7bmvm-editor_css',
        title: {
          children: (
            <span>
              <p>小程序</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'image~khhdjaooxvf',
              className: 'khhdjmeoz6l-editor_css',
              children: (<img src={mini_code} width="100%" alt="img" />),
            },
          ],
          className: 'khhdjwv4lye-editor_css',
        },
      },
      {
        name: 'block~khhdr1xqlgd',
        xs: 24,
        md: 6,
        className: 'block khhdri7bmvm-editor_css',
        title: {
          children: (
            <span>
              <p>合作咨询</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'image~khhdjaooxvf',
              className: 'khhdjmeoz6l-editor_css',
              children: (<img src={contact_code} width="100%" alt="img" />),
            },
          ],
          className: 'khhdjwv4lye-editor_css',
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
          <span>©2020 by 湖南卤一嘴餐饮管理有限公司 All Rights Reserved</span>
    ),
  },
};

import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './Bar/Nav2';
import Footer from './Bar/Footer1';
import Home from './Home';
import Brand from './Brand';
import Product from './Product';
import Merchant from './Merchant';
import Contact from './Contact';

import {
  Nav20DataSource,
  Footer10DataSource,
} from './Bar/data.source.js';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    return (
      <Router>
        <div>
          <Header id="Nav2_0" key="Nav2_0" dataSource={Nav20DataSource} isMobile={this.state.isMobile} />
          <Route exact path="/" component={Home} />
          <Route exact path="/brand" component={Brand} />
          <Route exact path="/product" component={Product} />
          <Route exact path="/merchant" component={Merchant} />
          <Route exact path="/contact" component={Contact} />
          <Footer id="Footer1_0" key="Footer1_0" dataSource={Footer10DataSource} isMobile={this.state.isMobile} />
        </div>
      </Router>
    );
  }
}

export default App;
import React from 'react';
export const Banner00DataSource = {
  wrapper: { className: 'banner0 kh7asfj3w37-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: (
      <span>
        <p>
          <br />
        </p>
      </span>
    ),
  },
  content: {
    className: 'banner0-content',
    children: (
      <span>
        <p>
          <br />
        </p>
      </span>
    ),
  },
  button: {
    className: 'banner0-button',
    children: (
      <span>
        <p>
          <br />
        </p>
      </span>
    ),
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper kh7atzaupxq-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>在线招聘</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>卤一嘴邀请您的加入</p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>招聘热线：400-1518-619&nbsp; &nbsp;电子邮箱：hr@luyizui.com</p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper kh7anlw1ci-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>内容策划主管</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <p>工作城市：长沙 </p>
          <p>招聘人数：1</p>
          <p>所属部门：企划部</p>
          <p>
            <br />
          </p>
          <p>岗位职责：</p>
          <p>
            1）深入了解品牌发展规划目标和拓展媒介资源，洞察相关行业／市场／消费者，提出品牌内容创意策略思路和逻辑；
          </p>
          <p>2）组织团队针对项目创意发想和讨论，带团队完成项目方案撰写；</p>
          <p>
            3）协同团队内部设计／文案等相关组的工作，对策划创意提案的整体出品负责；
          </p>
          <p>
            4）协助策划团队Leader，管理和培训策划创意团队，组织内部创意交流和积累；
          </p>
          <p>5）配合完成项目执行过程中的相关方案撰写和规划性工作；</p>
          <p>任职要求：</p>
          <p>1）有1-3年品牌策划或社交网络营销策划的经验；</p>
          <p>
            2）文笔好、脑洞大、审美佳，善于挖掘内容点，具有一定的洞察敏感度和创意发想能力，保证内容出品的质量和风格调性；
          </p>
          <p>
            3）思维活跃，基于市场营销理念和对品牌活动内容的了解为品牌不断激发新的内容思路；
          </p>
          <p>
            4）具有较强的理解能力能够主导部门撰写相关创意方案，具备良好的沟通能力；
          </p>
          <p>5）有较强的团队意识和时间观念，能适应团队的工作节奏；</p>
          <p>6）有广告公司或媒体经验者优先</p>
        </span>
      </span>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper kh7arwzj8bn-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/tvQTfCupGUFKSfQ.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>采购主管</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <p>工作城市：长沙 </p>
          <p>招聘人数：1</p>
          <p>所属部门：采购部</p>
          <p>
            <br />
          </p>
          <p>岗位职责：</p>
          <p>1）负责供应商资源的管理。</p>
          <p>
            2）根据总的成本目标，进行货品目标成本分解，核算及价格分析并与供应商进行价格谈判及供货进度谈判。
          </p>
          <p>
            3）合同条款评审。主要包括，生产采购及售后一般条款合同、服务合同等
          </p>
          <p>4）控制总的采购成本，并有效促进年度降本目标的达成。</p>
          <p>5）参与供应商质量改进工作，对供应商进行考核评价。</p>
          <p>
            6）负责潜在供应商资源的开发，组织供应商评审，将合格供应商纳入公司潜在供应商资源
          </p>
          <p>
            7）对列入潜在供应商清单的供应商进行成本核算及价格分析并根据采购目标价与供应商进行价格谈判及供货进度谈判。
          </p>
          <p>
            8）组织营运、加盟、市场等相关部门就供应商提供的货品质量处理方案进行评审
          </p>
          <p>
            9）负责采购货品的进度，完成货品供应中各种问题的信息反馈与业务沟通；监控货品入库，完成与供应商进行供货数量的核对工作。
          </p>
          <p>10）控制采购成本，跟踪付款。</p>
          <p>任职资格：</p>
          <p>1）大专及以上学历，管理类、食品类、物流类等相关专业；</p>
          <p>2）具有3年以上生鲜食品采购经验优先；</p>
          <p>3）熟悉采购流程，熟悉ERP系统，能独立完成采购工作；</p>
          <p>4）熟悉供应商评估、考核，熟悉相关质量体系标准；</p>
          <p>5）具备良好的市场调研、分析能力和人际沟通能力；</p>
          <p>
            6）服务意识强，工作认真仔细，对价格敏感，有较强的议价及谈判能力，具有较强的团队合作精神。
          </p>
        </span>
      </span>
    ),
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2018 <a href="https://motion.ant.design">Ant Motion</a> All Rights
        Reserved
      </span>
    ),
  },
};

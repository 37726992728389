/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner1 from './Banner1';
import Content13 from './Content13';
import Teams1 from './Teams1';

import {
  Banner10DataSource,
  Content130DataSource,
  Teams10DataSource,
} from './data.source';
import './less/antMotionStyle.less';

import { products } from '../data'

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      products: products,
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
    // this.fetchProducts();
  }

  render() {
    const children = [
      <Banner1
        id="Banner1_0"
        key="Banner1_0"
        dataSource={Banner10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content13
        id="Content13_0"
        key="Content13_0"
        dataSource={Content130DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    const productsView = this.state.products.map((item, i) => {
      var data = this.deepCopy(Teams10DataSource);
      data.titleWrapper.children[0].children = (
        <span>{item.name}</span>
      );
      var tempProduct = data.block.children[0];
      data.block.children = item.goods.map((gItem, gI) => {
        var p = this.deepCopy(tempProduct);
        p.titleWrapper.children[0].children = gItem.image;
        p.titleWrapper.children[1].children = gItem.goods_name;
        p.titleWrapper.children[2].children = gItem.intro;
        return p;
      });
      return (
        <Teams1
          id="Teams1_0"
          key="Teams1_0"
          dataSource={data}
          isMobile={this.state.isMobile}
        />
      );
    });
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
        {this.state.show && productsView}
      </div>
    );
  }

  fetchProducts() {
    var xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      console.log(xhr.responseText)
      this.setState({
        products: JSON.parse(xhr.responseText).data,
      });
    })
    xhr.open('GET', 'https://dc.quyun.info/addons/litestore/api.goods/product')
    xhr.send()
  }

  deepCopy(o) {
    return JSON.parse(JSON.stringify(o));
  }
  
}

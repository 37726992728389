import React from 'react';
import { brand } from '../data';

import quality1 from '../images/quality/1.png';
import quality2 from '../images/quality/2.png';
import quality3 from '../images/quality/3.png';
import quality4 from '../images/quality/4.png';
import quality5 from '../images/quality/5.png';

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper kh5tk5evuhm-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: 'https://os.alipayobjects.com/rmsportal/mlcYmsRilwraoAe.svg',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      { name: 'linkNav', to: '/', children: '首页', className: 'menu-item' },
      {
        name: 'linkNav~kh5tkth4c7',
        to: '/brand',
        children: '品牌介绍',
        className: 'menu-item',
      },
      {
        name: 'linkNav~kh5tkur7nlk',
        to: '/product',
        children: '产品中心',
        className: 'menu-item',
      },
      {
        name: 'linkNav~kh5tkvmeog',
        to: '/merchant',
        children: '特许经营',
        className: 'menu-item',
      },
      {
        name: 'linkNav~kh5tkwnkvk',
        to: '/contact',
        children: '在线招聘',
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper kh7ew83vks-editor_css',
        },
        bg: { className: 'bg kh7esaa78yq-editor_css' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper kh7evwsinwm-editor_css',
        },
        bg: { className: 'bg kh7esi272xo-editor_css' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper kh5zjzkhroo-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>品牌介绍</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: brand.info,
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        href: '/brand',
        children: (
          <span>
            <p>查看更多</p>
          </span>
        ),
      },
    },
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>我们的品质</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper kh60m4918lr-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 4,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={quality1} alt=""/>),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>优选食材</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>全球精选</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 4,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={quality2} alt=""/>),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>天然香料</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>食药同源</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 4,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={quality3} alt=""/>),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>新鲜可见</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>一日一卤</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kh60mbjmsr',
        className: 'content0-block',
        md: 4,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={quality4} alt=""/>),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>专属味道</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>自选配料</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kh60mp3xjh',
        className: 'content0-block',
        md: 4,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={quality5} alt=""/>),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>现拌鲜吃</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>入口鲜香</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 kh629otgpxq-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper kh7evnq2kro-editor_css' },
  title: { className: 'banner0-title', children: '' },
  content: {
    className: 'banner0-content',
    children: (
      <span>
        <p>
          <br />
        </p>
      </span>
    ),
  },
  button: {
    className: 'banner0-button',
    children: (
      <span>
        <span>
          <span>
            <p>
              <br />
            </p>
          </span>
        </span>
      </span>
    ),
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: 'Animation specification and components of Ant Design.',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品' },
        childWrapper: {
          children: [
            { name: 'link0', href: '#', children: '产品更新记录' },
            { name: 'link1', href: '#', children: 'API文档' },
            { name: 'link2', href: '#', children: '快速入门' },
            { name: 'link3', href: '#', children: '参考指南' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '#', name: 'link0', children: 'FAQ' },
            { href: '#', name: 'link1', children: '联系我们' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '资源' },
        childWrapper: {
          children: [
            { href: '#', name: 'link0', children: 'Ant Design' },
            { href: '#', name: 'link1', children: 'Ant Motion' },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2018 by <a href="https://motion.ant.design">Ant Motion</a> All Rights
        Reserved
      </span>
    ),
  },
};

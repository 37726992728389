import banner1 from './images/banner1.jpg';
import banner2 from './images/banner2.jpg';
import banner3 from './images/banner3.jpg';
import banner4 from './images/banner4.jpg';
import banner5 from './images/banner5.jpg';
import banner6 from './images/banner6.jpg';
import banner7 from './images/banner7.jpg';

import flow1 from './images/flow/1.png';
import flow2 from './images/flow/2.png';
import flow3 from './images/flow/3.png';
import flow4 from './images/flow/4.png';
import flow5 from './images/flow/5.png';
import flow6 from './images/flow/6.png';
import flow7 from './images/flow/7.png';
import flow8 from './images/flow/8.png';
import flow9 from './images/flow/9.png';
import flow10 from './images/flow/10.png';
import flow11 from './images/flow/11.png';

import p2_1 from './images/products/2_1.jpg';
import p2_2 from './images/products/2_2.jpg';
import p2_3 from './images/products/2_3.jpg';
import p2_4 from './images/products/2_4.jpg';
import p2_5 from './images/products/2_5.jpg';
import p2_6 from './images/products/2_6.jpg';
import p2_7 from './images/products/2_7.jpg';
import p2_8 from './images/products/2_8.jpg';
import p2_9 from './images/products/2_9.jpg';
import p2_10 from './images/products/2_10.jpg';
import p2_11 from './images/products/2_11.jpg';
import p3_1 from './images/products/3_1.jpg';
import p3_2 from './images/products/3_2.jpg';
import p3_3 from './images/products/3_3.jpg';
import p3_4 from './images/products/3_4.jpg';

export const banners = [banner1, banner2, banner3, banner4, banner5, banner6, banner7];
export const flowIcon = [flow1, flow2, flow3, flow4, flow5, flow6, flow7, flow8, flow9, flow10, flow11, ];

// 产品中心 品牌介绍
export const brand = {
    info: (
        <p>
        卤一嘴鲜捞热卤，传袭湖南传统卤味醇香口感，提炼“鲜”味划立品牌边界立足市场。<br />
        确定消费人群选址Mail及周边位置，迎合都市人对轻餐“方便、美味”基本需求的同时，搜罗湖南鱼米之乡，突出“绿色、健康”理念传达。<br />
        精选产品源头，严控生产标准，简化出品程序，精准冷链配送。<br />
        作为新时代鲜捞热卤细分实践者，门店形象持续升级，打造更适合都市人聚合的消费场景。<br />
        充沛生产制造产能，为品牌延展有了更大可能，完善线下堂食出品，线上零食分装售卖，相互辅助实现销售增量。<br />
        卤一嘴鲜捞热卤坚持差异化发展，正成为更多加盟者优质选择。<br />
        </p>
    ),
    character: {
        title: '产品性格',
        list: [
            { titile: '新鲜', detail: '新是原则，鲜是口感。每日食材进补均可溯源以新为原则；', icon: 'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png' },
            { titile: '健康', detail: '不添加任何添加剂和防腐剂的标准，小锅卤加独有配方让食品传递鲜香口感。', icon: 'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png' },
            { titile: '合群', detail: '正餐和零食属性相互融合，独自清欢或是三五结伴，满满人间风味。`', icon: 'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png' },
        ]
    }
};

// 特许经营
export const merchant = {
    // 合作模式
    type: [
        { goods_name: '区域合作', detail: '合同签订后有权使用卤一嘴品牌区域经营权', image: 'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png' },
        { goods_name: '单店合作', detail: '合同签订后有权使用卤一嘴品牌单店经营权', image: 'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png' },
    ],
    // 合作条件 
    condition: [
        '1、合作商需要参与店铺运营与培训',
        '2、自备创业资金15~20万以上',
        '3、具备良好的投资心态',
        '4、无信用不良以及犯罪记录',
        '5、认同卤一嘴使命、价值观携手共同打造热卤第一品牌产品描述',
    ],
    // 合作流程：
    flow: [
        '客户咨询',
        '合作评估',
        '邀约面谈',
        '签订合同',
        '选址确定',
        '培训安排',
        '门店筹建',
        '老师带店',
        '验收试营业',
        '外卖线上代运营',
        '验收店铺',
    ].map((value, index) => {
        return {title: value, icon: flowIcon[index]};
    }),
    // 技术支持
    support: {
        title: '特许经营',
        detail: '卤一嘴为特许经营商提供从选址建议到店内装修、员工培训、商品陈列开业促销活动策划、原料配送等一系列系统性支持服务',
        list: [
            {
                title: '店面选址',
                image: 'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
                detail: [
                    '商圈选址标准',
                    '优质店面推荐',
                    '免费进行店面评估指导',
                    '商场进驻协助'],
            },
            {
                title: '装修设计',
                image: 'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                detail: [
                    '门店设计',
                    '提供门店装修施工图',
                    '门店柜子施工图',
                    '店铺软装服务',
                    '全程装修协助指导',
                    '物料软装',
                    '招牌门头'],
            },
            {
                title: '新品支持',
                image: 'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
                detail: [
                    '季节性新品更新',
                    '新品免费培训',
                    '新品活动、海报、包装等全套方案'],
            },
            {
                title: '营销支持',
                image: 'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
                detail: [
                    '全程开店营销方案',
                    '巡店服务',
                    '抖音、微信公众号、微博、小程序等媒体渠道推广',
                    '试营业方案',
                    '开业活动方案',
                    '线上运营管理'],
            },
            {
                title: '技术培训支持',
                image: 'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
                detail: [
                    '提供4人技术免费培训',
                    '培训资料',
                    '运营手册',
                    '三天全封闭培训，不收取任何材料费',
                    '开业期间技术培训老师上门亲自带店指导策划开业活动方案',
                    '线上运营课程'],
            },
            {
                title: '市场管控机制',
                image: 'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
                detail: [
                    '统一价格',
                    '统一形象',
                    '统一促销',
                    '区域商圈',
                    '保护政策'],
            },
        ],
    },
};

// 页尾
export const footer = {
    // 合作咨询
    // 全国咨询热线
    phone: '400-1518-619',
    company: '湖南卤一嘴餐饮管理有限公司',
    contact: [
        '湖南卤一嘴餐饮管理有限公司',
        '总部地址：湖南省长沙市 岳麓区 芯城科技园1栋',
        '全国咨询热线：400-1518-619',
        '公司邮箱：luyizui888@163.com',
    ],
}

export const products = [
    {
        name: "肉食类", goods: [
            { goods_name: '牛肚', intro: '', image: p2_1 },
            { goods_name: '牛肉', intro: '', image: p2_2 },
            { goods_name: '猪五花肉', intro: '', image: p2_3 },
            { goods_name: '鸭脖', intro: '', image: p2_4 },
            { goods_name: '鸡肉', intro: '', image: p2_5 },
            { goods_name: '鸭头', intro: '', image: p2_6 },
            { goods_name: '鸭掌', intro: '', image: p2_7 },
            { goods_name: '猪耳朵', intro: '', image: p2_8 },
            { goods_name: '鸡翅尖', intro: '', image: p2_9 },
            { goods_name: '鸡小腿', intro: '', image: p2_10 },
            { goods_name: '秘制鸡爪', intro: '', image: p2_11 },
        ]
    },
    {
        name: "素食类", goods: [
            { goods_name: '藕片', intro: '', image: p3_1 },
            { goods_name: '土豆', intro: '', image: p3_2 },
            { goods_name: '五香豆腐', intro: '', image: p3_3 },
            { goods_name: '腐竹', intro: '', image: p3_4 },
        ]
    },
]
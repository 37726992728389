import React from 'react';
import contact_code from '../images/contact_code.jpg'
import arrow from '../images/arrow.svg'
import {merchant, footer} from '../data'
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg kh78eogdbp-editor_css' },
        title: { className: 'banner1-title', children: '' },
        content: {
          className: 'banner1-content',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
        button: {
          className: 'banner1-button',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>合作模式</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: merchant.type.map((value) => {
      return {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: value.image,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: value.title,
            },
            {
              name: 'content',
              children: value.detail,
            },
          ],
        },
      };
    }),
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>合作条件</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>TERMS OF CO-OP</p>
          </span>
        ),
      },
    ],
  },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/VHGOVdYyBwuyqCx.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>合作条件</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: merchant.condition.map((value) => {
            return (<p>{value}</p>)
          }),
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1',
        children: (
          <span>
            <p>合作流程</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: {
          href: '#Feature0_1',
          children: (
            <span>
              <p>马上咨询</p>
            </span>
          ),
          type: 'default',
          target: '',
        },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: merchant.flow.map((value) => {
            return {
              className: 'feature8-block-col',
              md: 4,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children: arrow,
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children: (<img width="32px" src={value.icon}/>),
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: value.title,
                  },
                ],
              },
            };
          }),
        },
      ],
    },
  },
};
export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: merchant.support.title,
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: merchant.support.detail,
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: merchant.support.list.map((value) => {
      return {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: value.image,
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: value.title,
          },
          content: {
            className: 'content3-content',
            children: value.detail.join('，'),
          },
        }
      };
    }),
  },
};
export const Feature01DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper kh7afz71jzi-editor_css',
  },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>合作咨询</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper kh79vl6v4ne-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon kh79vo3ws99-editor_css',
              children: contact_code,
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>扫描二维码获取特许经营申请</p>
                </span>
              ),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>全国咨询热线</p>
                  <p>{footer.phone}</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};

import React from 'react';
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper khhfk5n3tyn-editor_css',
        },
        bg: { className: 'bg kh76p7oa5o-editor_css' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper khhfm4lis1-editor_css',
        },
        bg: { className: 'bg kh76qgqhair-editor_css' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>PRODUCT</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>所有菜品</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>- 荤 - 素 - 组合 - 主食 - 甜品 -</p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>热卤小食</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper kh76jqdaois-editor_css',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 4,
        xs: 12,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image kh76kdtpt1q-editor_css',
              children: '',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: '招牌',
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: '软糯入味',
            },
          ],
        },
      },
    ],
  },
};
